<template>
  <div class="home">
    <headerNav></headerNav>
    <div class="header">
      <div class="header-main flex-i">
        <div>
            <img class="header-logo" src="../../assets/images/login/logo.png" alt="">
        <span class="header-title" @click="goIndex">榆阳产业服务平台营销商城</span>
        </div>
        <div class="header-search-box flex-s-b-i">
          <input class="header-search" type="text">
          <button class="sch_button" @click="goList">搜索</button>
          <!-- <div class="header-search-btn">搜索</div> -->
        </div>
      </div>
      <!-- <img src="" alt="">
      <span>榆阳产业服务平台营销商城</span> -->
    </div>
    <!-- <img style="margin-top: 20px" src="../../assets/images/index.png" alt=""> -->

    <div class="goodsContent">
      <div class="goodsCenter">
        <div class="goodsGg">
          <div style="width: 460px; height: 460px;">
              <el-carousel ref="carousel" trigger="click" :autoplay="false" indicator-position="none" height="460px" style="width: 460px">
                <el-carousel-item v-for="item in choosePicObjects" :key="item">
                  <img style="width: 460px; height: 460px" :src="item.commodityPicUrl" alt="">
                </el-carousel-item>
              </el-carousel>
          </div>
          
          <div style="margin-top: 10px; background: #fff">
            <el-carousel trigger="click" :autoplay="false" height="84px" indicator-position="none" style="width: 460px; background: #fff">
                <el-carousel-item v-for="(item,index) in picList" :key="index" style="background: #fff; text-align: left;">
                  <img :class="inx == 1 || inx == 3 ? 'm10' : ''" v-for="(it,inx) in item.childList" :style="{border:(picChoose == it.id ? '1px solid #378400' : '0')}" :key="inx" @click="handleClickPic(it,inx+(index*5))" style="width: 84px; height: 84px" :src="it.commodityPicUrl" alt="">

                </el-carousel-item>
              </el-carousel>
          </div>
        </div>


        <div class="goodsTitle">
          <h3 class="by2" style="line-height: 30px">{{goodsDetail.commodityName}}</h3>
          <div class="goods_price">
            <p>价格</p>
            <h4>￥{{price / 10000}}</h4>
            <div>
              <p>销量<span>{{pjForm.spuId}}</span></p>
              <p>评价<span>1291</span></p>
            </div>
          </div>

          <div class="goods_adress">
            <div><span>发货地</span> <span>陕西省 榆林市</span></div>
            <!-- <div><span style="line-height: 28px">配送至</span> <span style="border-radius: 8px; border: 1px solid #D8D8D8; padding: 6px 20px">北京市 东城区 北新桥街道 雍和大厦A座</span></div> -->
            <div v-for="(item,index) in goodsDetail.uccSpuSpecRspBOList" :key="index" style="display: flex"><span style="flex-shrink: 0">{{item.propName}}</span><div style="flex: 1; flex-wrap: wrap; display: flex;">
              <span v-for="(it,inx) in item.uccSpuSpecValueListRspBOList" :key="inx" @click="clickLx(it)" class="leixing" :class="it.flag ? 'chooseLx' : ''">{{it.propValue}}</span>
              </div></div>
            <div style="margin: 10px 0 50px"><span>数量（件）</span> <span>库存{{kucun ? kucun : 9999}}件</span></div>
          </div>

          <div style="text-align: center">
            <el-button style="margin-right: 30px" @click="setFavorites" v-if="isFavorites == false" type="warning">收藏商品</el-button>
            <el-button style="margin-right: 30px" @click="cancelFavorites" v-if="isFavorites == true" type="info">取消收藏</el-button>
            <!-- <el-button type="success">扫码购买</el-button> -->
          </div>
        </div>


        <div class="shop_detail">
          <img class="shop_logo" src="../../assets/images/shoplogo.png" alt="">
          <h2 class="shop_title"><img src="../../assets/images/logo.png" alt=""><span>{{shopName}}</span></h2>
          <ul class="shop_alice">
            <li>
              <p>评价</p><span>5.0</span>
            </li>
            <li>
              <p>物流</p><span>5.0</span>
            </li>
            <li>
              <p>销量</p><span>5.0</span>
            </li>
          </ul>
          <div ref="code" class="code"></div>
        </div>
      </div>
    </div>

    <div class="tabClick">
      <ul>
        <li @click="tabClick(true)" :class="tabChange ? 'choose_tab' : ''"><p>商品详情</p><span></span></li>
        <li @click="tabClick(false)" :class="!tabChange ? 'choose_tab' : ''"><p>累计评价</p><span></span></li>
      </ul>
    </div>

    <div class="goods_detail">
      <div v-if="tabChange" class="goods_detail_left">
        <h5>商品参数</h5>
        <ul>
          <li v-for="(item,index) in goodsDetail.uccCommodityParameterRspBOList" :key="index">{{item.propName}}：{{item.propValue}}</li>
        </ul>
        <div class="detailPic" v-html="goodsDetail.uccCommodityDetailRspBO.commodityPcDetailChar"></div>
      </div>
      <div v-else class="goods_detail_left">
        <h6>
          <el-radio v-model="pjForm.searchType" label="">全部</el-radio>
          <el-radio v-model="pjForm.searchType" label="1">有图</el-radio>
          <el-radio v-model="pjForm.searchType" label="3">好评</el-radio>
          <el-radio v-model="pjForm.searchType" label="4">中评</el-radio>
          <el-radio v-model="pjForm.searchType" label="5">差评</el-radio>
          <!-- <el-radio v-model="radio" label="2">追评</el-radio> -->
        </h6>

        <div class="pingjia" v-for="(item,index) in pjList" :key="index" style="border-bottom: 1px solid #f4f4f4">
          <div class="pingjia_person">
            <img src="../../assets/images/logo.png" alt="">
            <p>{{item.commentUserName}}</p>
          </div>
          <div class="pingjia_content">
            <p>{{item.buyerComment}}</p>
            <div style="margin-right: 10px">
              <img style="width: 50px; height: 50px; margin-top: 10px; border-radius: 8px;" src="../../assets/images/logo.png" alt="">
            </div>
            <div style="margin-top: 10px; color: #999">
              <span>{{item.createTime}}</span>  <span style="margin-left: 40px" v-for="(it,inx) in item.lxList" :key="inx">{{it}}</span>
            </div>
          </div>
        </div>
        
        <div style="text-align: right; margin-top: 40px">
          <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
        </div>

      </div>
      <div class="goods_detail_right">

      </div>
      
    </div>

    <div class="bottom">
        <p class="aboutus"><span>关于我们</span><span></span><span>联系我们</span></p>
        <p class="banquan">Copyright©2004-2021  蜂窝FW.com 版权所有</p>
    </div>

    <div style="height: 30px; background: rgba(132, 199, 84, 1)">
      
    </div>

  </div>
</template>

<script>
import headerNav from './../../components/headerNav'
import QRCode from 'qrcodejs2'
import '../../assets/css/home.scss'
// @ is an alias to /src
export default {
  name: 'home',
  data(){
      return{
        shopName: '',
        isFavorites: false, // 判断是否关注
        goodsDetail: {},
        pjForm: {
          spuId: this.$route.query.id,
          pageNo: 1,
          pageSize: 10,
          searchType: '',
        },
        total: 0,
        kucun: '',
        price: '',
        picList: [],
        pjList: [],
        picChoose: -1,
        choosePic: '',
        choosePicObjects : [],
        tabChange: true
      }
  },
  components: {
    headerNav
  },
  mounted(){
    this.qryGoods();
    this.qryPj();
    setTimeout(() => {
    var qrcode = new QRCode(this.$refs[`code`], {
        text: "https://mall.yyzhny.com/productDetails?id=" + this.$route.query.id,
        width: 150,
        height: 150,
        colorDark : "#000000",
        colorLight : "#ffffff",
        correctLevel : QRCode.CorrectLevel.H
      })
    },500)
    
    // new QRCode(document.getElementById('code'), 'www.baidu.com')
    // {
    //     text: 'www.baidu.com', // 需要转换为二维码的内容
    //     // width: 100,
    //     // height: 100,
    //     // colorDark: '#9F721D',
    //     // colorLight: '#ffffff',
    // }
    
    if(sessionStorage.getItem("userData")){
        if(!(JSON.stringify(sessionStorage.getItem("userData")) == "{}")){ 
          this.createFootprint();
          this.isFavoritesS();
        }
    }
  },
  methods:{
    goIndex() {
      this.$router.push({
        path: '/',
      })
    },
    //判断是否关注商品
    isFavoritesS() {
      this.qa.queryByCommodityFavoritesByUserIdAndCommodityId({commodityId: this.pjForm.spuId}).then(res => {
        if(res.data.favoritesStatus == "0") {
          this.isFavorites = false
        }else {
          this.isFavorites = true
        }
      })
    },
    // 设置关注
    setFavorites() {
      this.qa.createCommodityFavorites({commodityId: this.pjForm.spuId}).then(res => {
        this.$message({
          message: '商品收藏成功',
          type: 'success'
        });
        this.isFavoritesS()
      })
    },
    // 取消关注
    cancelFavorites() {
      this.qa.batchCancelCommodityFavoritesByCommdityIds({commodityIds: this.pjForm.spuId}).then(res => {
        this.$message({
          message: '商品已取消收藏',
          type: 'success'
        });
        this.isFavoritesS()
      })
    },
    //创建足迹
    createFootprint() {
      this.qa.createFootprint({commodityId: this.pjForm.spuId}).then(res => {
        return
      })
    },
    qryPj(){
      this.qa.queryUocCommodityCommentPageList(this.pjForm).then(res => {
        for(let i = 0; i < res.data.rows.length; i++) {
          res.data.rows[i].lxList = res.data.rows[i].attrNames.split(",");
        }
        this.pjList = res.data.rows;
        this.total = res.data.recordsTotal;
      })
    },
    qryGoods(){
      let list = [];
      this.qa.queryUccCommodityDetailsByApplet({id: this.$route.query.id}).then(res => {
        
        for(let z = 0; z < res.data.uccSpuSpecRspBOList.length; z++) {
          for(let x = 0; x < res.data.uccSpuSpecRspBOList[z].uccSpuSpecValueListRspBOList.length; x++) {
            if(x == 0) {
              res.data.uccSpuSpecRspBOList[z].uccSpuSpecValueListRspBOList[x].flag = true;
              list.push(res.data.uccSpuSpecRspBOList[z].uccSpuSpecValueListRspBOList[x].propValue)
            } else {
              res.data.uccSpuSpecRspBOList[z].uccSpuSpecValueListRspBOList[x].flag = false;
            }
            
          }
        }
        this.qa.queryAttributeValueBySku({attributeValues: list.join(","),commodityId: this.$route.query.id}).then(res => {
          this.price = res.data.uccSkuPriceRspBO.singleSalePrice;
          this.kucun = res.data.uccSkuStockRspBO.stockNum;
        })
        this.shopName = res.data.shopName;
        this.goodsDetail = res.data;
        console.log(this.goodsDetail)
        this.choosePicObjects = res.data.uccCommodityPicRspBOList;
        this.choosePic = res.data.uccCommodityPicRspBOList[0].commodityPicUrl;
        for(var i = 0; i < res.data.uccCommodityPicRspBOList.length;) {
          let obj = {};
          obj.childList = [];
          for(let j = 0; j < 5; j++) {
            // console.log(res.data.uccCommodityPicRspBOList[i*5+j])
            if(i*5+j < res.data.uccCommodityPicRspBOList.length) {
              obj.childList.push(res.data.uccCommodityPicRspBOList[i*5+j]);
            }
            
          }
          i=i+5;
          this.picList.push(obj);
        }
      })
      
    },
    clickLx(row){
      let list = [];
      for(let z = 0; z < this.goodsDetail.uccSpuSpecRspBOList.length; z++) {
          for(let x = 0; x < this.goodsDetail.uccSpuSpecRspBOList[z].uccSpuSpecValueListRspBOList.length; x++) {
            if(row.id == this.goodsDetail.uccSpuSpecRspBOList[z].uccSpuSpecValueListRspBOList[x].id) {
              this.goodsDetail.uccSpuSpecRspBOList[z].uccSpuSpecValueListRspBOList[x].flag = true;
            } else {
              this.goodsDetail.uccSpuSpecRspBOList[z].uccSpuSpecValueListRspBOList[x].flag = false;
            }
            if(this.goodsDetail.uccSpuSpecRspBOList[z].uccSpuSpecValueListRspBOList[x].flag == true) {
              list.push(this.goodsDetail.uccSpuSpecRspBOList[z].uccSpuSpecValueListRspBOList[x].propValue)
            }
          }
        }

        this.qa.queryAttributeValueBySku({attributeValues: list.join(","),commodityId: this.$route.query.id}).then(res => {
          this.price = res.data.uccSkuPriceRspBO.singleSalePrice;
          this.kucun = res.data.uccSkuStockRspBO.stockNum;
        })

    },
    tabClick(flag){
      this.tabChange = flag;
    },
    handleClickPic(row,index){

      this.$refs.carousel.setActiveItem(index);
      this.picChoose = row.id;
      this.choosePic = row.commodityPicUrl;
    },
    showCatalog(){
      // this.tag = 
    },
    goList(){
      this.$router.push({
        name: 'goodsList',
        query: {
          name: '1111'
        }
      })      
    },
    handleSizeChange(page) {
      this.pjForm.pageSize = page;
      this.qryPj();
    },
    handleCurrentChange(page) {
      this.pjForm.pageNo = page;
      this.qryPj();
    }
  }
}
</script>
<style scoped>

  .home>>>.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .home>>>.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
  .home {
    background: #fff;
  }
  .m10 {
    margin: 0 8px;
  }
  .header {
    width: 100%;
    height: 126px;
    background: #fff;
  }
  .header-main {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
  }
  .header-logo {
    width: 60px;
    height: 60px;
  }
  .header-title {
    font-size: 28px;
    color: 333;
    font-weight: 600;
    margin: 0 37px 0 10px;
  }
  .header-search-box {
    width: 718px;
    height: 46px;
    display: flex;
    align-items: center;
    border: 1px solid #84C754;
    box-sizing: border-box;
    border-radius: 8px;
    padding-left: 21px;
  }
  .header-search {
    border: 0;
    
  }
  .header-search-btn {
    width: 106px;
    height: 44px;
    line-height: 44p[x];
    border: 0 8px 8px 0;
  }
  .sch_button {
    width: 106px;
    height: 46px;
    border: 0;
    margin-right: -1px;
    background: #84C754;
    font-weight: 800;
    color: #FFFFFF;
    border-radius: 0px 8px 8px 0px;
    cursor: pointer;
  }
  .goodsContent {
    width: 100%;
    background: #fff;
    margin-bottom: 55px;
  }
  .goodsCenter {
    width: 1200px;
    margin: 0 auto;
    display: flex;
  }
  .goodsGg {
    /* display: flex; */
    margin-right: 30px;
  }
  .goodsTitle {
    font-size: 20px;
    font-weight: 500;
    margin-right: 30px;
    color: #222222;
    text-align: left;
    width: 500px;
  }
  .goods_price {
    display: flex;
    height: 86px;
    background: rgba(132, 199, 84, 0.1);
    border-radius: 8px;
    padding: 0 20px;
    margin-top: 20px;
  }
  .goods_price >p {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 80px;
    margin-right: 50px;
  }
  .goods_price h4 {
    font-size: 36px;
    font-weight: 500;
    color: #378400;
    line-height: 80px;
  }
  .goods_price >div {
    margin-left: auto;
  }
  .goods_price >div >p {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 40px;
  }
  .goods_price >div >p:nth-child(1) {
    border-bottom: 1px solid #d8d8d8;
  }
  .goods_adress {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    text-align: left;
  }
  .goods_adress >div {
    margin: 30px 0 10px;
  } 
  .goods_adress >div >div {
    display: inline-block;
    vertical-align: middle;
  }
  .goods_adress >div >span {
    display: inline-block;
    vertical-align: middle;
  }
  .goods_adress >div >span:nth-child(1) {
    width: 120px;
  }
  .leixing {
    border-radius: 8px; 
    border: 1px solid #D8D8D8; 
    padding: 6px 20px;
    margin-bottom: 10px;
    margin-right: 10px;
    cursor: pointer;
  }
  .shop_detail {
    width: 240px;
    border-radius: 8px;
    border: 1px solid #D8D8D8;
    padding: 20px 30px;
    height: 370px;
    flex-shrink: 0;
  }
  .shop_detail .shop_logo {
    width: 60px;
  }
  .shop_detail .shop_title {
    height: 18px;
    line-height: 16px;
    margin-top: 10px;
  }
  .shop_detail .shop_title >img {
    width: 16px;
    vertical-align: middle;
    margin-right: 8px;
  }
  .shop_detail .shop_title >span {
    vertical-align: middle;
    font-size: 18px;
  }
  .shop_alice {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  .shop_alice >li >p {
    margin-bottom: 10px;
  }
  .tabClick {
    width: 100%;
    height: 80px;
    background: #f7f7f7;
    
  }
  .tabClick >ul {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    padding-top: 20px;
  }
  .tabClick >ul li {
    height: 60px;
    width: 200px;
    line-height: 60px;
    color: #333;
    font-size: 16px;
    border-radius: 8px 8px 0px 0px;
    cursor: pointer;
  }
  .tabClick >ul li:hover {
    background: #fff;
    color: #84C754;
  }
  .tabClick >ul .choose_tab {
    background: #fff;
    color: #84C754;
  }
  .tabClick >ul .choose_tab span {
    display: block;
    width: 40px;
    height: 4px;
    background: #84C754;
    border-radius: 2px;
    margin: -10px auto 0;
    
  }
  .goods_detail {
    width: 1200px;
    margin: 20px auto 30px;
    display: flex;
  }
  .goods_detail_left {
    width: 930px;
    margin-right: 30px;
  }
  .goods_detail_left >h5 {
    background: #EFFAE8;
    height: 40px;
    line-height: 40px;
    text-align: left;
    padding: 0 30px;
  }
  .goods_detail_left >ul {
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border: 1px solid #EFFAE8;
    margin-bottom: 20px;
  }
  .goods_detail_left >ul li {
    width: 25%;
    margin-bottom: 12px;
  }
  .goods_detail_right {
    width: 240px;
  }
  .detailPic>>>img{
    width: 100%;
  }
  .goods_detail_left >h6 {
    text-align: left;
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: #F7F7F7;
    border-radius: 8px;
    padding: 0 50px;
  }
  .pingjia {
    display: flex;
  }
  .pingjia .pingjia_person {
    width: 170px;
    flex-shrink: 0;
    text-align: center;
    padding: 10px 0 20px;
  }
  .pingjia .pingjia_person img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  .pingjia .pingjia_person p {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
  }
  .pingjia .pingjia_content {
    padding: 10px 0 20px;
    text-align: left;
    line-height: 20px;
  }
  .chooseLx {
    border: 1px solid #84C754;
  }
  .code {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
</style>